import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { diamond } from 'assets';
import {
	_getUser,
	_isUserLoggedIn,
	_isAnEmptyObject,
	getTodayDate,
	formatMoney,
} from 'utils';
import { DailyBonus } from 'components/daily-bonus/DailyBonus';
import { Carousel } from 'components/landingPageComps/Carousel';
import { UserLevel } from 'components/landingPageComps/UserLevel';
import { UserControls } from 'components/landingPageComps/UserControls';
import Background from 'assets/seasons/active/home/home_bg.png';
import SoundPlayer from 'components/sounds/GameSounds';
import CircularLoader from 'components/loader/CircularLoader';
import { userActions } from 'store/slices/user/userSlice';
import { getUserProfile } from 'api/profileApiService';
import { getSubscriptionDetail } from 'api/apiRequest';
import { getLoginDailyBonusApi } from 'api/profileApiService';
import { TelegramBotModal } from 'components/modals/TelegramBotModal';
import { UserSubscriptionsModal } from 'components/modals/subscription/UserSubscriptions';
import { NewestHunterModal } from 'components/modals/homepage-modals/HomepageModal';
import { GemsDetailModal } from 'components/modals/account/GemsDetailModal';
import { SpecialOfferModal } from 'components/modals/homepage-modals/SpecialOffer';

export interface IModalProp {
	status: boolean;
	type?: string;
	modalObj?: object;
}

// Extend the Window interface
declare global {
	interface Window {
		Telegram?: {
			WebApp?: {
				initData?: any;
				initDataUnsafe?: {
					user?: any;
					start_param?: any;
				};
				openTelegramLink: (url: string) => void;
				openLink: (url: string) => void;
				sendInvoice: any;
				isExpanded: boolean;
				expand: () => void;
				ready: () => Promise<void>;
				openUrl: (url: string) => void;
			};
		};
	}
}

export function NewHomePage() {
	const user = _getUser();
	const loggedIn = _isUserLoggedIn();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const MINIMUM_INTERVAL_MINS = 30;

	const [, setOpenModal] = useState<IModalProp>({
		status: false,
		type: '',
		modalObj: {},
	});
	const [newPlayer, setNewPlayer] = useState(false);

	useEffect(() => {
		const isFirstiTme = localStorage.getItem('new-player');
		if (!isFirstiTme || isFirstiTme !== 'false') {
			setNewPlayer(true);
		}
	}, []);

	const [openDailyBonus, setOpenDailyBonus] = useState(false);

	const [modal, setModal] = useState({ isOpen: false, type: '', modalObj: {} });
	const modalOpen = (type: string, modalObj?: any) =>
		setModal({ isOpen: true, type: type, modalObj });
	const modalClose = (e?: boolean) =>
		setModal({ isOpen: e || false, type: '', modalObj: {} });

	const { data: dailyBonusData } = useQuery(
		['daily-bonus'],
		async () => await getLoginDailyBonusApi(),
		{
			retry: 0,
			enabled: !_isAnEmptyObject(user),
		}
	);

	const { data: userProfileData, isLoading } = useQuery(
		['user-profile'],
		async () => await getUserProfile(),
		{
			onSuccess: (res: any) => {
				console.log('true');

				dispatch(userActions.addUserProfile(res?.data));
				localStorage.setItem(
					'userCurrency',
					res?.data?.localCurrency?.local?.symbol
				);
			},
			retry: 0,
			enabled: !_isAnEmptyObject(user),
		}
	);

	const { data: activeSubDetail } = useQuery(
		['get-sub-detail'],
		async () => await getSubscriptionDetail(),
		{
			onSuccess: (res: any) => {
				const isActiveSubExist = res?.data?.find(
					(item: any) => item?.isActive === true
				);
				dispatch(userActions.addUserSubscription(isActiveSubExist));
			},
			retry: 0,
			enabled: !_isAnEmptyObject(user),
		}
	);

	const isActiveSubExist = activeSubDetail?.data?.find(
		(item: any) => item?.isActive === true
	);

	useEffect(() => {
		const isTodayInDailyBonusData = dailyBonusData?.data?.find(
			(item: any) =>
				item.day?.todayDate === getTodayDate() &&
				item.day?.action === 'consecutive_login'
		);

		if (isTodayInDailyBonusData?.day?.isClaimed === false) {
			setOpenDailyBonus(true);
		} else {
			setOpenDailyBonus(false);
		}
	}, [dailyBonusData?.data, setOpenDailyBonus]);

	const navigateToGameRoute = (route: any, params?: any) => {
		SoundPlayer.playSound('menuSelect');
		navigate(`/${route}`, params);
	};

	const previousLevelPoint =
		userProfileData?.data?.gemsEconomy?.previousLevelFactor?.point || 0;

	const currentLevelPoint =
		userProfileData?.data?.gemsEconomy?.member?.currentPoint || 0;

	const nextLevelPoint =
		userProfileData?.data?.gemsEconomy?.nextLevelFactor?.point || 0;
	//This function gets percentage
	const getPercentage = () => {
		const percentage =
			((currentLevelPoint - previousLevelPoint) * 100) /
			(nextLevelPoint - previousLevelPoint);
		return percentage;
	};

	// Preload the background image
	useEffect(() => {
		const preloadImage = (src: string) => {
			const img = new Image();
			img.src = src;
		};

		preloadImage(Background);
	}, []);

	const [isModalChecked, setIsModalChecked] = useState(false);

	useEffect(() => {
		const checkAndOpenModal = async () => {
			await new Promise((resolve) => setTimeout(resolve, 5000));

			const lastPopupTime = localStorage.getItem('lastModalPopupTime');
			const now = new Date();
			const lastPopupDate = lastPopupTime
				? new Date(lastPopupTime)
				: new Date(0);
			const diffMins = (now.getTime() - lastPopupDate.getTime()) / (1000 * 60);

			if (diffMins >= MINIMUM_INTERVAL_MINS) {
				const randomAdditionalMins = Math.random() * 60;
				const nextPopupTime = new Date(
					now.getTime() + randomAdditionalMins * 60000
				);

				if (isActiveSubExist?.isActive !== true) {
					modalOpen('special-offer');
					localStorage.setItem(
						'lastModalPopupTime',
						nextPopupTime.toISOString()
					);
				}
			}
		};

		if (!isModalChecked && isActiveSubExist?.isActive !== undefined) {
			checkAndOpenModal();
			setIsModalChecked(true);
		}
	}, [isActiveSubExist]);

	return (
		<div className="relative w-full h-full">
			{isLoading ? (
				<CircularLoader />
			) : (
				<>
					<div className="h-[18%]">
						<div className="w-full hidden tb:block mb-8 relative z-10">
							<Carousel
								user={user}
								setOpen={setOpenModal}
								isMobile={false}
								navigateToGameRoute={navigateToGameRoute}
							/>
						</div>
						<div className="w-full block tb:hidden relative z-10">
							<Carousel
								user={user}
								setOpen={setOpenModal}
								isMobile={true}
								navigateToGameRoute={navigateToGameRoute}
							/>
						</div>
						<div className="flex items-center justify-between gap-8 px-3 mt-4">
							{/* Gems earned section */}
							{!_isAnEmptyObject(user) && (
								<div
									onClick={() => modalOpen('gem-detail')}
									className="px-5 py-1 ml-2 h-[40px] flex items-center justify-between w-full bg-[#FFFFFF0A] border border-[#FFFFFF14] rounded-[12px] relative"
								>
									<div
										style={{ backdropFilter: 'blur(16px)' }}
										className="absolute -bottom-0.5 -left-2 bg-[#FFFFFF0A] border border-[#FFFFFF14]  w-[42px] h-[42px] rounded-full flex items-center justify-center"
									>
										<img src={diamond} className="w-[2rem]" />
									</div>
									<p className="text-[1.1rem] text-white font-medium pl-5">
										{userProfileData?.data?.gemsEconomy?.member?.foreverGems > 0
											? formatMoney(
													Number(
														userProfileData?.data?.gemsEconomy?.member
															?.foreverGems
													),
													true
												)
											: '0'}
									</p>
								</div>
							)}

							{/* User section */}
							{loggedIn && (
								<UserLevel
									user={user}
									getPercentage={getPercentage}
									userProfileData={userProfileData}
									isActiveSubExist={isActiveSubExist}
									modalOpen={modalOpen}
								/>
							)}
						</div>
					</div>
					<div
						className="px-3 relative"
						style={{
							height: '70%',
						}}
					>
						<UserControls user={user} isActiveSubExist={isActiveSubExist} />
					</div>
				</>
			)}
			{openDailyBonus && (
				<>
					<DailyBonus open={openDailyBonus} setOpen={setOpenDailyBonus} />
				</>
			)}
			{modal.isOpen && modal.type === 'subscriptions' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={true}
					modalChild={
						<UserSubscriptionsModal isActiveSubExist={isActiveSubExist} />
					}
				/>
			)}

			{modal.isOpen && modal.type === 'gem-detail' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={false}
					modalChild={
						<GemsDetailModal
							modalClose={modalClose}
							gemsBalance={
								userProfileData?.data?.gemsEconomy?.member?.foreverGems > 0
									? formatMoney(
											Number(
												userProfileData?.data?.gemsEconomy?.member?.foreverGems
											),
											true
										)
									: '0'
							}
							dailyGemsBalance={
								userProfileData?.data?.gemsEconomy?.member?.todayGems > 0
									? formatMoney(
											Number(
												userProfileData?.data?.gemsEconomy?.member?.todayGems
											),
											true
										)
									: '0'
							}
							weeklyGemsBalance={
								userProfileData?.data?.gemsEconomy?.member?.todayGems > 0
									? formatMoney(
											Number(
												userProfileData?.data?.gemsEconomy?.member?.thisWeekGems
											),
											true
										)
									: '0'
							}
						/>
					}
				/>
			)}

			{modal.isOpen && modal.type === 'special-offer' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={false}
					modalChild={<SpecialOfferModal modalClose={modalClose} />}
				/>
			)}

			{newPlayer && (
				<NewestHunterModal
					closeModal={modalClose}
					setNewPlayer={setNewPlayer}
				/>
			)}
		</div>
	);
}
