import { ReactNode } from 'react';
import { SidebarHomePage } from 'components/sidebar/SidebarHomePage';
import { Navbar } from 'components/navigation/Navbar';
import Background from 'assets/seasons/active/home/home_bg.png';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
	showSidebar?: boolean;
};

export const HomePageLayout = ({
	children,
	theme,
	toggleTheme,
	showSidebar = true,
}: ILayoutProps) => {
	return (
		<div
			className="bg-primary w-screen h-screen overflow-hidden bg-center bg-cover"
			style={{ backgroundImage: `url(${Background})` }}
		>
			<Navbar
				theme={theme}
				toggleTheme={toggleTheme}
				className="h-[8vh] md:h-[5rem] "
			/>
			<main className="w-full h-[90vh] min-h-0 lg:min-h-[90vh] overflow-y-auto overflow-x-hidden flex ">
				{showSidebar && (
					<SidebarHomePage className="hidden lg:block lg:w-[20rem] h-full overflow-hidden" />
				)}
				<main className="w-full h-full overflow-y-auto md:pt-6 pt-3 pb-3 px-0 md:px-6 ">
					{children}
				</main>
			</main>
			{showSidebar && (
				<div className="px-3 fixed bottom-3 w-full z-[20]">
					<SidebarHomePage className="flex items-center lg:hidden md:flex w-full h-[10vh] px-2 bg-[#FFFFFF14] border border-solid border-[#FFFFFF14] rounded-[20px] backdrop-blur-[15px]" />
				</div>
			)}
		</div>
	);
};
