import { lazy, Suspense } from 'react';
import { getPositionText } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button3dMini } from 'components/button/Button';
import PlayIcon from 'assets/seasons/active/home/play-icon.png';
import InviteIcon from 'assets/seasons/active/home/invite-icon.png';
import PremiumIcon from 'assets/seasons/active/home/premium-icon.png';
import TournamentIcon from 'assets/icons/tournament-star.png';
import LeaderboardIcon from 'assets/seasons/active/home/leaderboard-icon.png';
import { getDailyLeaderboardData } from 'store/slices/gameCenter/gameCenterApi';
import { diamond } from 'assets';
import SoundPlayer from 'components/sounds/GameSounds';

interface Prop {
	user?: any;
	isActiveSubExist?: any;
	userProfileData?: any;
}

// Lazy load the images
const OdogwuUser = lazy(() =>
	import('assets/images/odogwu_full_pose.png').then((module) => ({
		default: () => <img src={module.default} alt="Odogwu" />,
	}))
);
const ShaggiUser = lazy(() =>
	import('assets/images/shaggyHome.png').then((module) => ({
		default: () => <img src={module.default} alt="Shaggi" />,
	}))
);
const IndaboskiUser = lazy(() =>
	import('assets/images/indaboskiHome.png').then((module) => ({
		default: () => <img src={module.default} alt="Indaboski" />,
	}))
);

const getImageComponentByDay = () => {
	const dayOfWeek = new Date().getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
	const images = [
		IndaboskiUser, // Sunday
		OdogwuUser, // Monday
		ShaggiUser, // Tuesday
		OdogwuUser, // Wednesday
		IndaboskiUser, // Thursday
		ShaggiUser, // Friday
		OdogwuUser, // Saturday
	];

	return images[dayOfWeek];
};

export const UserControls = ({ user, isActiveSubExist }: Prop) => {
	const navigate = useNavigate();

	const { data: gemsDataToay } = useQuery(
		['leaderbaord-today'],
		async () => await getDailyLeaderboardData(user?._id),
		{
			enabled: user != undefined && user != null,
		}
	);
	const userPosition = gemsDataToay?.data?.userPosition;

	const goToSubscriptions = () => {
		if (isActiveSubExist) {
			navigate('/manage-subscription');
		} else {
			navigate('/subscriptions');
		}
	};

	const ImageComponent = getImageComponentByDay();

	// Update the type of ImageComponent to accept props
	const ImageComponentWithProps = (
		props: React.ImgHTMLAttributes<HTMLImageElement>
	) => {
		const Component = ImageComponent;
		return <Component {...props} />;
	};

	return (
		<div
			className="px-2 py-1 w-full relative"
			style={{
				height: '100%',
				display: 'block',
			}}
		>
			<Suspense fallback={<div>Loading...</div>}>
				<ImageComponentWithProps
					className="mx-auto h-[683px]"
					alt="avatar"
					style={{ height: '683px' }}
				/>
			</Suspense>

			{user !== null && (
				<div
					className="absolute top-1/2 left-1/2 mx-auto w-full min-h-[200px]"
					style={{
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<div className="w-full flex flex-col gap-8">
						<div className="w-full flex items-center justify-between gap-8">
							<div
								onClick={() => {
									SoundPlayer.playSound('menuSelect');
									navigate('/prizes/leaderboard');
								}}
								className="flex flex-col items-center justify-center -gap-2 cursor-pointer relative "
							>
								<img
									src={LeaderboardIcon}
									className="w-[72px] ml-1 h-[72px] object-contain"
									alt="leaderboard"
									width={72}
									height={72}
								/>
								<div style={{ marginLeft: -85 }}>
									<ControlText
										text={getPositionText(userPosition?.userBoardPosition)}
									/>
								</div>
							</div>

							<div
								onClick={() => {
									SoundPlayer.playSound('menuSelect');
									navigate('/games');
								}}
								className="flex flex-col items-center mr-2 justify-center -gap-2 cursor-pointer relative "
							>
								<img
									src={PlayIcon}
									className="w-[72px]  h-[72px] object-contain"
									alt="play"
									width={72}
									height={72}
								/>
								<ControlText text={'Play Now!'} />
							</div>
						</div>
						<div className="w-full flex items-center justify-between gap-8">
							<div
								onClick={() => {
									SoundPlayer.playSound('menuSelect');
									navigate('/account/referrals');
								}}
								className="flex flex-col items-center justify-center -gap-2 cursor-pointer relative "
							>
								<img
									src={InviteIcon}
									className="w-[72px] h-[72px] ml-1 object-contain"
									alt="invite"
									width={72}
									height={72}
								/>
								<ControlText text={'+1,000'} icon={true} />
							</div>

							<div
								onClick={() => {
									SoundPlayer.playSound('menuSelect');
									goToSubscriptions();
								}}
								className="flex flex-col items-center justify-center -gap-2 cursor-pointer relative "
							>
								<img
									src={PremiumIcon}
									className="w-[90px] h-[78px] object-contain"
									alt="premium"
									width={90}
									height={78}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="absolute -bottom-[1%] left-0 right-0 w-full">
				<div
					style={{ backdropFilter: 'blur(10px)' }}
					className="flex justify-between items-center gap-4 w-full bg-[#00000052] border border-[#FFFFFF14] rounded-[8px] px-3 py-2"
				>
					<div className="flex items-center gap-2">
						<img
							src={TournamentIcon}
							className="w-[44px] object-contain"
							alt="tournament"
						/>
						<div>
							<p className="text-md font-medium text-[#FFECCB]">
								<span className="inline-block w-2 h-2 bg-red-800 rounded-full mr-2 animate-ping"></span>
								Live Tournaments
							</p>
							{/* <p className="text-[14px] text-primary font-medium">04:34:32</p> */}
						</div>
					</div>
					<Button3dMini
						text={'Play Now'}
						className={`font-semibold flex items-center justify-center h-[2rem] min-w-[120px]`}
						onClick={() => {
							navigate('/tournaments');
							SoundPlayer.playSound('menuSelect');
						}}
						textClass={'text-sm'}
					/>
				</div>
			</div>
		</div>
	);
};

export const ControlText = ({ text, icon }: any) => {
	return (
		// <div className="absolute -bottom-3 left-0 bg-[#08172D80] border-[#FFFFFF14] w-[80px] h-[30px] rounded-[8px] flex items-center justify-center">
		<div className="absolute -bottom-3 left-0 bg-[#95092CB2] border-[#FFFFFF14] w-[80px] h-[30px] rounded-[8px] flex items-center justify-center">
			<div className="flex flex-row">
				{icon === true && <img src={diamond} className="w-[0.9rem] mr-1" />}
				<span className="text-[13px] font-medium text-primary">{text}</span>
			</div>
		</div>
	);
};
