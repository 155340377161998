import { diamond } from 'assets';
import { Button3d } from 'components/button/Button';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';

export interface Props {
	modalClose: () => void;
	gemsBalance: any;
	dailyGemsBalance: any;
	weeklyGemsBalance: any;
}

export const GemsDetailModal = ({
	modalClose,
	gemsBalance,
	dailyGemsBalance,
	weeklyGemsBalance,
}: Props) => {
	const { t } = useTranslation('common');

	return (
		<div className="h-full w-full">
			<main className="absolute bottom-0 pb-[3rem] w-full flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="w-full relative max-w-[360px] px-4 md:px-6 mt-[2.5rem] h-full ">
					<div className="absolute mx-auto -top-[6rem] left-0 right-0 flex justify-center">
						<div className="relative w-fit">
							<img
								src={diamond}
								alt="gameCoin"
								className="mx-auto h-[112px] w-[112px]"
							/>
						</div>
					</div>

					<div className="mt-[2.5rem] mb-8 text-primary text-center">
						<p className="text-[1.5rem] font-medium mb-3">Gems</p>
						<p className="text-[1rem] text-[#FFFFFF99] font-medium text-center">
							{t('gemsExplainer')}
						</p>
					</div>

					<div className="bg-[#FFFFFF0A] px-4 py-6 border border-[#FFFFFF14] rounded-[12px] mb-6">
						<p className="text-[1rem] text-[#FFFFFF80] text-center mb-2">
							Gem {t('util.balance')}
						</p>
						<div className="flex items-center justify-center gap-3">
							<img src={diamond} alt="gameCoin" className="h-[24px] w-[24px]" />
							<span className="font-medium text-[24px] text-primary">
								{gemsBalance}
							</span>
						</div>
					</div>

					<div className="bg-[#FFFFFF0A] px-4 py-2 border border-[#FFFFFF14] rounded-[12px] mb-8">
						<p className="text-[1rem] text-[#FFFFFF80] text-center mb-2">
							{`This Week's Gems`}
						</p>
						<div className="flex items-center justify-center gap-3">
							<img src={diamond} alt="gameCoin" className="h-[26px] w-[26px]" />
							<span className="font-medium text-[20px] text-primary">
								{weeklyGemsBalance}
							</span>
						</div>
						<p className="text-[1rem] text-[#FFFFFF80] text-center mt-4 mb-2">
							{`Today's Gems`}
						</p>
						<div className="flex items-center justify-center gap-3">
							<img src={diamond} alt="gameCoin" className="h-[26px] w-[26px]" />
							<span className="font-medium text-[20px] text-primary">
								{dailyGemsBalance}
							</span>
						</div>
					</div>

					<Button3d
						text={'Close'}
						onClick={() => modalClose()}
						disabled={false}
						className={`font-semibold w-full flex items-center justify-center h-[56px] mt-5mb-5`}
					/>
				</div>
			</main>
		</div>
	);
};
