import { InputHTMLAttributes, ReactNode, forwardRef, useState } from 'react';
import { password_show, password_hide } from 'assets';

export interface TextInputProps
	extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	passwordInput?: boolean;
	prefixLabel?: any;
	suffixLabel?: ReactNode;
	setValue?: (e?: any) => void;
	required?: boolean;
	label: string;
	labelIcon?: string;
	subLabel?: ReactNode;
	errorMessage?: any;
	inputInfo?: ReactNode;
	icon?: ReactNode;
	iconClick?: () => void;
	prefix?: string;
	onClick?: (e: any) => void;
	onFocus?: (e: any) => void;
	onChange?: (e: any) => void;
	max?: any;
	disabled?: boolean;
}

const TextInput = forwardRef(
	(
		{
			name,
			value,
			// onClick,
			onChange,
			// setValue,
			label,
			labelIcon,
			subLabel,
			// iconClick,
			passwordInput,
			type,
			required,
			errorMessage,
			inputInfo,
			// icon,
			className,
			disabled,
			max,
			...rest
		}: TextInputProps,
		ref: React.Ref<HTMLInputElement>
	) => {
		const [iconSwitch, setSwitch] = useState(false);

		const switchIcon = () => setSwitch(!iconSwitch);

		return (
			<div className={`${className}`}>
				<div className="flex justify-between items-center">
					{label && (
						<div className="w-full flex items-center mb-1">
							<label
								className={`text-[13px] md:text-[14px] capitalize leading-[21px] text-grey font-medium `}
							>
								{label}
							</label>
							{labelIcon && <img src={labelIcon} alt="" className="ml-2" />}
							{required && <span className="text-text-red ml-[3px]"> * </span>}
						</div>
					)}
					{subLabel && <>{subLabel}</>}
				</div>

				<main
					className={`w-full mb-3 flex items-center justify-between relative`}
				>
					<input
						{...rest}
						type={
							type ? type : passwordInput && !iconSwitch ? 'password' : 'text'
						}
						ref={ref}
						name={name}
						value={value}
						onChange={onChange}
						disabled={disabled}
						max={max}
						autoComplete="off"
						data-testid="text-input"
						className={`w-full h-[56px] rounded-[43px] bg-[#0F121D] focus:bg-[#0F121D] outline-0 px-3 text-[0.875rem] text-center focus:outline-none focus:text-white ${
							!disabled
								? 'text-white border-[#84C70D] focus:border-[#84C70D]'
								: 'text-grey focus:border-none'
						} font-medium placeholder-[#232838] border-2 border-[#232838]  leading-normal`}
					/>

					{passwordInput && (
						<div className="absolute right-4" onClick={() => switchIcon()}>
							{
								<img
									src={iconSwitch ? password_show : password_hide}
									alt="icons"
									className="cursor-pointer"
								/>
							}
						</div>
					)}
				</main>
				{inputInfo && <>{inputInfo}</>}
				{errorMessage && (
					<div className="w-full ">
						<span className="text-text-red text-[12px] ">{errorMessage}</span>
					</div>
				)}
			</div>
		);
	}
);

TextInput.displayName = 'MyApp';

export default TextInput;
